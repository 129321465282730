import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { rhythm, scale } from "../utils/typography";

export default function BlogPostTemplate({ data, pageContext, location }) {
  function renderDates(frontmatter) {
    return (
      <p
        style={{
          ...scale(-1 / 5),
          display: `block`,
          marginBottom: rhythm(1),
          marginTop: rhythm(-0.8),
        }}
      >
        {frontmatter.date !== frontmatter.updated
          ? `Updated on ${frontmatter.updated}`
          : frontmatter.date}
      </p>
    );
  }

  function Comment({ commentBox }) {
    return <div ref={commentBox} className="comments" />;
  }

  const post = data.markdownRemark;
  const tags = data.markdownRemark.frontmatter.tags;
  const siteTitle = data.site.siteMetadata.title;
  const { previous, next } = pageContext;

  const commentBox = React.createRef();

  useEffect(() => {
    const commentScript = document.createElement("script");
    const theme =
      typeof window !== "undefined" && localStorage.getItem("theme") === "dark"
        ? "github-dark"
        : "github-light";
    commentScript.async = true;
    commentScript.src = "https://utteranc.es/client.js";
    commentScript.setAttribute("repo", "lfkwtz/lefkowitz.me-comments");
    commentScript.setAttribute("issue-term", "pathname");
    commentScript.setAttribute("id", "utterances");
    commentScript.setAttribute("theme", theme);
    commentScript.setAttribute("crossorigin", "anonymous");
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript);
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`);
    }
  }, []);

  return (
    <Layout location={location} title={siteTitle} tags={tags}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <h1>{post.frontmatter.title}</h1>
      {renderDates(post.frontmatter)}
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <hr />
      <div id="comments">
        {/* <h2>Comments</h2> */}
        <Comment commentBox={commentBox} />
      </div>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      {/* <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`;
